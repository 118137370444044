import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PendingAppointmentData } from '@dougs/accounting/onboarding/dto';
import { AppointmentNotificationDatePipe, AvatarComponent } from '@dougs/ds';

@Component({
  selector: 'dougs-accounting-onboarder-appointment-notification-pending',
  templateUrl: './accounting-onboarder-appointment-notification-pending.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AvatarComponent, AppointmentNotificationDatePipe],
  providers: [],
})
export class AccountingOnboarderAppointmentNotificationPendingComponent {
  @Input() pendingAppointmentData: PendingAppointmentData = {};
}
