import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AvatarComponent } from '@dougs/ds';
import { AccountingOnboarderAppointmentNotificationComponentService } from '../../services/accounting-onboarder-appointment-notification.component.service';
import { AccountingOnboarderAppointmentNotificationPendingComponent } from './accounting-onboarder-appointment-notification-pending/accounting-onboarder-appointment-notification-pending.component';
import { AccountingOnboarderAppointmentNotificationTakeComponent } from './accounting-onboarder-appointment-notification-take/accounting-onboarder-appointment-notification-take.component';

@Component({
  selector: 'dougs-accounting-onboarder-appointment-notification',
  templateUrl: './accounting-onboarder-appointment-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    AccountingOnboarderAppointmentNotificationPendingComponent,
    AccountingOnboarderAppointmentNotificationTakeComponent,
  ],
  providers: [AccountingOnboarderAppointmentNotificationComponentService],
})
export class AccountingOnboarderAppointmentNotificationComponent {
  constructor(
    // eslint-disable-next-line max-len
    public readonly accountingOnboarderAppointmentNotificationComponentService: AccountingOnboarderAppointmentNotificationComponentService,
  ) {}
}
