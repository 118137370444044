import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarComponent } from '@dougs/ds';

@Component({
  selector: 'dougs-accounting-onboarder-appointment-notification-take',
  templateUrl: './accounting-onboarder-appointment-notification-take.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AvatarComponent],
  providers: [],
})
export class AccountingOnboarderAppointmentNotificationTakeComponent {
  @Input() appointmentUrl = '#';
}
