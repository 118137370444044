import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService } from '@dougs/core/routing';
import { ConfirmationModalComponent, ModalService } from '@dougs/ds';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class CompanyCreationXSellComponentService {
  constructor(
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly userStateService: UserStateService,
    private readonly router: Router,
    private readonly companyStateService: CompanyStateService,
    private readonly routingService: RoutingService,
  ) {}

  private readonly isLoading: WritableSignal<boolean> = signal<boolean>(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  async startCreationService(): Promise<void> {
    if (!this.isLoading()) {
      this.metricsService.pushMixpanelEvent('Menu Accounting Create Company Link Clicked');

      const data: unknown = await this.openConfirmationModal();

      if (!data || !this.companyStateService.activeCompany?.id) {
        return;
      }

      this.pushEvent();

      this.isLoading.set(true);

      await this.companyServicesStateService.startCreationService(this.companyStateService.activeCompany.id);
      await this.userStateService.refreshActiveUser(this.userStateService.activeUser);
      await this.companyStateService.refreshCompany();
      await this.companyServicesStateService.refreshServices(this.companyStateService.activeCompany.id);
      await this.router.navigateByUrl(
        this.routingService
          .createUrl([this.routingService.getRelevantOnboardingCreaUrl(this.userStateService.activeUser)])
          .toString(),
      );
    }
  }

  async openConfirmationModal(): Promise<unknown> {
    return (
      await lastValueFrom(
        this.modalService.open(ConfirmationModalComponent, {
          data: {
            title: 'Création de votre entreprise',
            body: `Nos juristes vous accompagnent jusqu'au Kbis.<br>Vous allez être redirigé vers le parcours de création d'entreprise.`,
            noText: 'Annuler',
            yesText: 'Démarrer',
            secondaryColor: true,
          },
        }).afterClosed$,
      )
    ).data;
  }

  pushEvent(): void {
    this.metricsService.pushMixpanelEvent('Cross-sell Creation Start Creation CTA Clicked');
    this.metricsService.pushGAEvent('Cross-sell Creation Start Creation CTA Clicked');
  }
}
