@if (
  accountingOnboarderAppointmentNotificationComponentService.pendingAppointmentData$ | async;
  as pendingAppointmentData
) {
  <dougs-accounting-onboarder-appointment-notification-pending [pendingAppointmentData]="pendingAppointmentData" />
} @else {
  @if (
    accountingOnboarderAppointmentNotificationComponentService.accountingOnboarderAppointmentCustomerTask$ | async;
    as accountingOnboarderAppointmentCustomerTask
  ) {
    <dougs-accounting-onboarder-appointment-notification-take
      [appointmentUrl]="accountingOnboarderAppointmentCustomerTask.metadata?.calendlyUrl"
    />
  }
}
