import { Injectable } from '@angular/core';
import { isAfter } from 'date-fns';
import { map } from 'rxjs/operators';
import { APPOINTMENT_TYPE } from '@dougs/accounting/onboarding/dto';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { CUSTOMER_APPOINTMENT_TASK_CODE, UserTasksStateService } from '@dougs/task/shared';

@Injectable()
export class AccountingOnboarderAppointmentNotificationComponentService {
  constructor(
    private readonly accountingOnboardingStateService: AccountingOnboardingStateService,
    private readonly userTasksStateService: UserTasksStateService,
  ) {}

  pendingAppointmentData$ = this.accountingOnboardingStateService.accountingOnboarding$.pipe(
    map((accountingOnboarding) => {
      const pendingAppointment = accountingOnboarding?.data?.accountingOnboarderAppointments?.pendingAppointment;

      if (!pendingAppointment?.scheduledAt || isAfter(new Date(), pendingAppointment.scheduledAt)) {
        return null;
      }

      return pendingAppointment;
    }),
  );

  accountingOnboarderAppointmentCustomerTask$ = this.userTasksStateService.tasks$.pipe(
    map((tasks) =>
      tasks.find(
        (task) =>
          task.code === CUSTOMER_APPOINTMENT_TASK_CODE &&
          task.metadata?.appointmentType === APPOINTMENT_TYPE.AccountingOnboarderAppointment,
      ),
    ),
  );
}
