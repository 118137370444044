/* eslint-disable no-console */
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import * as Sentry from '@sentry/angular-ivy';
import { replayIntegration } from '@sentry/angular-ivy';
import { httpClientIntegration } from '@sentry/integrations';
import { AppConfig } from '@dougs/core/config';
import { AppComponent } from './app/app.component';

registerLocaleData(localeFr);

fetch('config.json', { cache: 'reload' })
  .then(async (config) => {
    AppConfig.settings = await config.json();

    await bootstrap(AppConfig.settings.production);
  })
  .catch(async () => {
    AppConfig.settings = {
      production: true,
      name: 'prod',
      logger: 'error',
      legacyApiServerUrl: '',
      legacyApiServerClientId: 'ci_GHv9lcUwVQpyvIT1h8a9JA5F16lZ7A1d',
      signupApiServerUrl: '/api/sign-me-up',
      mixpanel: 'abfb3ddd94efba90ddab3f7212b7b896',
      ga: 'G-56JXD5BHBG',
      gtm: 'GTM-NRWTV8J',
      hubspot: {
        disabled: false,
        trackingCode: '9258772',
      },
      clarity: '4j47gfwp13',
      pixel: '518333528949729',
      stripe: 'pk_live_94cviGMMOLZ1g48hGQn4Fi1t',
      socket: {
        apiKey: '2a2be07923d6c342ac40',
        cluster: 'eu',
        authEndpoint: '/api/pusher/auth',
      },
      intercom: {
        disabled: true,
        appId: 'm2r93wjs',
      },
      datadogRum: {
        applicationId: '0308abff-8faa-47b6-b037-1907c7159465',
        clientToken: 'pubee6225ce369828c8c19feb4fc181e461',
        env: 'production',
      },
      crm: {
        baseUrl: '/dougs-crm',
      },
      displayApi: {
        baseUrl: '/display-api',
      },
      sso: {
        clientId: 'fake-client-id',
      },
      signup: {
        disabled: true,
      },
      enableInteractions: true,
      inpiGU: {
        baseUrl: 'https://guichet-unique.inpi.fr',
      },
      sentry: {
        dsn: 'https://912d0ec1338ea2acc3dbdab46132fabe@o47695.ingest.sentry.io/4506630898712576',
      },
    };
    await bootstrap(AppConfig.settings.production);
  });

const bootstrap: (production: boolean) => Promise<void> = async (production = false): Promise<void> => {
  if (production) {
    Sentry.init({
      dsn: AppConfig.settings.sentry.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        replayIntegration(),
        httpClientIntegration(),
      ],
      tracePropagationTargets: ['localhost', /^https:\/\/app.dougs\.fr/],
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    });
    enableProdMode();
  }

  const { appConfig } = await import('./app/app.config');

  await bootstrapApplication(AppComponent, appConfig).catch((err) => console.log(err));
};
